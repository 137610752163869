.App {
  text-align: center;
}

/* .popup {
  animation: popup 0.5s ease-in-out;
}

@keyframes popup {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
} */